<template>
    <div class="Ranking">
        <div class="type-text">
            {{ isIntegral ? "我的积分" : isId ? "TA的成长值" : "我的成长值" }}
            <!-- <img
                @mouseover="showTipWh = true"
                @mouseout="showTipWh = false"
                src="@/assets/ic_wh@2x.png"
                class="wh-img click"
            /> -->
        </div>
        <div class="total" v-if="isIntegral">{{ myIntegral }}</div>
        <div class="re" v-if="!isIntegral">
            <img src="@/assets/image_czz@2x.png" class="ball" alt="" />
            <div class="growth">{{ my_growth }}</div>
        </div>
        <div class="flex-jsa wid_100">
            <div class="flex-alc column click" @click="active = 0">
                <div class="tag-text" :class="{ 'sel-tag-text': active == 0 }">
                    {{ isIntegral ? "积分记录" : "成长值记录" }}
                </div>
                <div
                    class="red-line"
                    :class="{ 'back-red': active == 0 }"
                ></div>
            </div>
            <div class="flex-alc column click" @click="active = 1">
                <div
                    class="tag-text re"
                    :class="{ 'sel-tag-text': active == 1 }"
                    @mouseover="showTipWh = true"
                    @mouseout="showTipWh = false"
                >
                    {{ isIntegral ? "积分排行榜" : "成长值排行" }}
                    <div
                        class="wh-tip"
                        :style="{ display: showTipWh ? 'flex' : 'none' }"
                    >
                        {{
                            isIntegral
                                ? "完成课程学习后获得源源不断的积分哦~"
                                : "您需要完成个人导师给您布置的专项任务方可获得成长值喔~"
                        }}
                    </div>
                </div>
                <div
                    class="red-line"
                    :class="{ 'back-red': active == 1 }"
                ></div>
            </div>
        </div>
        <el-divider></el-divider>
        <div
            class="pr-27 pl-27 wid_100 pb-39 record-container"
            v-show="active == 0"
            v-infinite-scroll="loadRecord"
        >
            <NoData
                v-if="!record.length"
                :text="isIntegral ? '您还没有积分记录' : '还没有成长值记录'"
            ></NoData>
            <div
                class="record"
                v-for="(item, index) in record"
                :key="'record' + index"
            >
                <div>
                    <div class="text">
                        完成了{{ isIntegral ? item.name : item.content }}
                    </div>
                    <div class="time">
                        {{ isIntegral ? item.created : item.fmt_created }}
                    </div>
                </div>
                <div class="add">
                    +{{ isIntegral ? item.credit : item.score }}
                </div>
            </div>
        </div>

        <div class="pl-20 pr-20 wid_100" v-show="active == 1">
            <div class="mb-10 flex-jsb back-gray">
                <div class="flex-alc">
                    <img
                        :src="
                            isIntegral
                                ? user.avatar ||
                                  require('@/assets/defulatAvatar.png')
                                : avatar ||
                                  require('@/assets/defulatAvatar.png')
                        "
                        class="head mr-14"
                        alt=""
                    />
                    <div class="name">{{ isIntegral ? user.name : name }}</div>
                </div>
                <div class="flex-alc">
                    <div class="now-rangking">
                        {{ isId ? "TA" : "我" }}当前的排名
                    </div>
                    <div
                        class="num-rangking"
                        :style="{
                            'font-size': myRanking > 10 ? '12px' : '24px',
                        }"
                    >
                        {{ myRanking > 10 ? "未上榜" : myRanking }}
                    </div>
                </div>
            </div>
            <div class="overflow">
                <div
                    class="rangking-card"
                    v-for="(item, index) in rankingData"
                    :key="'rangk' + index"
                >
                    <div class="flex-alc">
                        <img
                            :src="ImgList[index]"
                            v-show="index < 3"
                            class="img"
                            alt=""
                        />
                        <div class="outerRing" v-show="index > 2">
                            <div class="InnerRing">
                                {{ index + 1 }}
                            </div>
                        </div>
                        <img
                            class="head"
                            :src="
                                item.avatar ||
                                require('@/assets/defulatAvatar.png')
                            "
                            alt=""
                        />
                        <div class="text">{{ item.name }}</div>
                    </div>
                    <div class="num">
                        {{ isIntegral ? item.credit : item.amount }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NoData from "@/components/NoData.vue";
    export default {
        name: "Ranking",
        components: {
            NoData,
        },
        props: {
            isIntegral: {
                type: Boolean,
                default: true,
            }, //积分/成长
            my_growth: Number, //我的成长值
            rankingData: Array, //成长值/积分排行榜
            myRanking: Number, //我的排行
            record: Array, //记录
            isId: Boolean, //是否为徒弟的成长值
            name: {
                type: String,
            },
            avatar: {
                type: String,
            },

            myIntegral: Number, //我的积分
        },
        computed: {
            user: function () {
                return this.$store.state.user;
            },
        },
        data() {
            return {
                active: 1,
                ImgList: [
                    require("@/assets/jp.png"),
                    require("@/assets/yp.png"),
                    require("@/assets/tp.png"),
                ], //金银铜排行图
                showTipWh: false, //显示积分成长值获取方式
            };
        },
        methods: {
            loadRecord() {
                this.$emit("loadRecord");
            },
        },
    };
</script>

<style lang="less" scoped>
.Ranking {
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    padding-bottom: 20px;
    .type-text {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 10px;
        padding-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        .wh-img {
            width: 20px;
            height: 20px;
            margin-left: 2px;
        }
    }
    .total {
        font-size: 36px;
        font-weight: 500;
        color: #ff3143;
        margin-bottom: 21px;
    }
    .ball {
        width: 111px;
        height: 111px;
    }
    .growth {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        font-weight: 500;
        color: #ffffff;
    }
    .tag-text {
        font-size: 16px;
        font-weight: 500;
        color: #000000;

        .wh-tip {
            position: absolute;
            padding: 0 10px;
            top: -90px;
            left: -60px;
            width: 273px;
            height: 69px;
            background: #ffffff;
            box-shadow: 0px 2px 9px 0px #c2c2c2;
            border-radius: 5px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #fe3247;
            display: none;
            align-items: center;
            justify-content: center;
            z-index: 99;
        }
    }
    .sel-tag-text {
        color: #ff3143;
    }
    .red-line {
        width: 54px;
        height: 5px;
        border-radius: 6px;
        margin-top: 5px;
    }
    .back-red {
        background: #ff3143;
    }
    .el-divider--horizontal {
        margin-top: 12px;
        margin-bottom: 30px;
    }
    .record-container {
        max-height: 622px;
        overflow-y: scroll;

        .record {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 2px solid #ededed;
            width: 100%;
            .text {
                font-size: 16px;
                font-weight: 500;
                color: #000000;
            }
            .time {
                font-size: 16px;
                font-weight: 400;
                color: #808080;
                margin-top: 8px;
            }
            .add {
                width: 52px;
                height: 52px;
                border: 1px solid #fcdddd;
                font-size: 18px;
                color: #e41414;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
            }
        }
    }
    .record-container::-webkit-scrollbar {
        opacity: 0;
    }
    .overflow::-webkit-scrollbar {
        opacity: 0;
    }
    .overflow {
        max-height: 622px;
        overflow-y: scroll;
    }
    .back-gray {
        background: #f6f6f6;
        padding: 11px 16px;
        border-radius: 6px;
        .head {
            width: 41px;
            height: 41px;
            border-radius: 100%;
        }
        .name {
            font-size: 16px;
            font-weight: 500;
            color: #2d2d2d;
        }
        .now-rangking {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
        }
        .num-rangking {
            width: 45px;
            height: 45px;
            border: 1px solid #e41414;
            font-weight: 700;
            color: #e41414;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            margin-left: 12px;
        }
    }
    .rangking-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        padding: 13px 10px;
        box-shadow: 0px 1px 0px 0px #f2f2f2;
        .img {
            width: 23px;
            height: 29px;
            margin-right: 59px;
        }
        .head {
            width: 34px;
            height: 34px;
            border-radius: 100%;
            margin-right: 13px;
        }
        .text {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
        }
        .outerRing {
            width: 22px;
            height: 22px;
            background: #ededed;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 59px;
            .InnerRing {
                width: 16px;
                height: 16px;
                background: #b3b3b3;
                font-size: 12px;
                font-weight: 900;
                color: #ffffff;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .num {
            width: 197px;
            font-size: 14px;
            font-weight: 700;
            color: #e41414;
        }
    }
}
</style>